<template>
    <div>
      <b-sidebar
        id="sidebar-save-transaction"
        sidebar-class="sidebar-lg"
        :visible="saveTransactionSidebar.visible"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @shown="onShow"
        @change="(val) => mutateSaveTransactionSidebar({ visible: val })"
        @hidden="clear"
      >
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h4 class="mb-0">Editar Transação</h4>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="onSubmit">
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-form-group label="Tipo de Pagamento" label-for="payment-type">
                  <v-select
                    id="payment-type"
                    v-model="paymentType"
                    :options="paymentTypes"
                    label="name"
                    :reduce="type => type.id"
                    :class="{ 'is-invalid': v$.paymentType.$error }"
                    :disabled="loading.paymentTypes"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.paymentType.required.$invalid">
                      Você deve selecionar um tipo de pagamento
                    </span>
                  </div>
                </b-form-group>
              </b-col>
  
              <b-col cols="12" class="mb-2">
                <b-form-group label="Gateway de Pagamento" label-for="payment-gateway">
                  <v-select
                    id="payment-gateway"
                    v-model="paymentGateway"
                    :options="paymentGateways"
                    label="name"
                    :reduce="gateway => gateway.id"
                    :disabled="loading.paymentGateways"
                  />
                </b-form-group>
              </b-col>
  
              <b-col cols="12" class="mb-2">
                <b-form-group label="Identificador do pagamento" label-for="gateway-reference-id">
                  <b-form-input
                    id="gateway-reference-id"
                    v-model="paymentGatewayReferenceId"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2">
                <b-form-group label="Parcelas" label-for="transaction-installments">
                  <b-form-input
                    id="transaction-installments"
                    v-model="installments"
                    type="number"
                    :class="{ 'is-invalid': v$.installments.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.installments.required.$invalid || v$.installments.minValue.$invalid">
                      Você deve informar um valor válido maior que zero
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2">
                <b-form-group label="Valor" label-for="amount">
                  <b-form-input
                    id="amount"
                    v-model="amount"
                    v-money="moneyConfig"
                    :class="{ 'is-invalid': v$.amount.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.amount.required.$invalid || v$.amount.minValue.$invalid">
                      Você deve informar um valor válido maior que zero
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                type="submit"
                :disabled="saving"
              >
                {{ saving ? 'Salvando...' : 'Salvar' }}
              </b-button>
            </div>
          </b-form>
        </template>
      </b-sidebar>
    </div>
  </template>
  
<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VMoney } from 'v-money'
import { moneyToFloat, floatToMoney } from '@/helpers/converters'
import * as types from '@/modules/contract/store/types'
import * as sharedTypes from '@/modules/shared/store/types'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BRow,
    BFormInput,
    vSelect,
  },
  directives: {
    money: VMoney,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  data() {
    return {
      loading: {
        paymentTypes: false,
        paymentGateways: false,
      },
      saving: false,
      paymentType: undefined,
      paymentGateway: undefined,
      paymentGatewayReferenceId: '',
      amount: undefined,
      moneyConfig: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      installments: 1
    }
  },
  computed: {
    ...mapGetters({
      saveTransactionSidebar: types.SAVE_TRANSACTION_SIDEBAR,
      paymentTypes: sharedTypes.PAYMENT_TYPES,
      paymentGateways: sharedTypes.PAYMENT_GATEWAYS,
    }),
  },
  validations() {
    return {
      paymentType: { required },
      paymentGateway: { },
      paymentGatewayReferenceId: { },
      amount: { required, minValue: function(value){
          if(value){
            let amount = moneyToFloat(value)
            if(amount > 0){
              return true
            }
            return false
          }
          return false
        } 
      },
      installments: { required, minValue: function(value){
          return value > 0
        } 
      },
    }
  },
  mounted() {
    this.loading.paymentTypes = true
    this.getPaymentTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: 'Ocorreu um erro ao carregar os tipos de pagamento. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading.paymentTypes = false
      })
    this.loading.paymentGateways = true
    this.getPaymentGateways()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: 'Ocorreu um erro ao carregar os gateways de pagamento. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading.paymentGateways = false
      })
  },
  methods: {
    ...mapMutations({
      mutateSaveTransactionSidebar: types.MUTATE_SAVE_TRANSACTION_SIDEBAR,
    }),
    ...mapActions({
      updateTransaction: types.UPDATE_TRANSACTION,
      getTransaction: types.GET_TRANSACTION,
      getPaymentTypes: sharedTypes.GET_PAYMENT_TYPES,
      getPaymentGateways: sharedTypes.GET_PAYMENT_GATEWAYS,
    }),
    onShow() {
      if (this.saveTransactionSidebar?.id) {
        this.getTransaction(this.saveTransactionSidebar.id)
          .then((response) => {
            if (response.data) {
              this.paymentType = response.data.payment_type_id
              this.paymentGateway = response.data.payment_gateway_id
              this.paymentGatewayReferenceId = response.data.payment_gateway_reference_id
              this.amount = floatToMoney(response.data.amount)
              this.installments = response.data.installments
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: 'Ocorreu um erro ao carregar a transação. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            this.mutateEditTransactionSidebar({ visible: false })
          })
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      const payload = {
        id: this.saveTransactionSidebar.id,
        contract_id: this.$route.params.id,
        payment_type_id: this.paymentType,
        payment_gateway_id: this.paymentGateway,
        payment_gateway_reference_id: this.paymentGatewayReferenceId,
        amount: moneyToFloat(this.amount),
        installments: this.installments
      }
      this.updateTransaction(payload)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: 'Transação atualizada com sucesso!',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.mutateSaveTransactionSidebar({ visible: false })
          this.saveTransactionSidebar.saveAction()
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: 'Ocorreu um erro ao atualizar a transação. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    clear() {
      this.paymentType = undefined
      this.paymentGateway = undefined
      this.paymentGatewayReferenceId = ''
      this.amount = undefined
      this.mutateSaveTransactionSidebar({ id: undefined })
      this.v$.$reset()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>